const dictionary = {
  en: {
    custom: {
      email: {
        required: 'Required',
        email: 'Invalid email address'
      },
      name: {
        required: 'Required',
        max: (field, [length]) => 'Max 30 characters only'
      },
      password: {
        required: 'Required',
        max: (field, [length]) => 'Max 20 characters only',
        min: (field, [length]) => 'Min 8 characters only'
      },
      confirmPassword: {
        required: 'Required',
        confirmed: 'Must match the password field'
      },
      districts: {
        required: 'Required'
      },
      fishs: {
        required: 'Required'
      },
      lbs: {
        required: 'Required',
        min: (field, [length]) => 'Min 0 is required'
      },
      ozs: {
        required: 'Required',
        max: (field, [length]) => 'Max 15 ozs to the lb',
        min: (field, [length]) => 'Min 0 is required'
      },
      dr: {
        required: 'Required',
        max: (field, [length]) => 'Max 15 dr to the oz',
        min: (field, [length]) => 'Min 0 is required'
      },
      displayDescription: {
        required: 'Required',
        max: (field, [length]) => 'Max 300 characters only'
      }
    }
  }
}

export default dictionary
