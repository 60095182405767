import api from '../api/utils'

const state = {
  weather: {},
  location: {
    lat: null,
    lng: null,
  },
  astronomy: {},
}

const getters = {
  getWeather: state => {
    return state.weather
  },
  getAstronomy: state => {
    return state.astronomy
  },
  getLocation: state => {
    return state.location
  },
}

const actions = {
  setWeather({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      return api.setWeather(payload)
        .then((response) => {
          commit('setWeather', response)
          resolve(response)
        })
    })
  },
  setAstronomy({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      return api.setAstronomy(payload)
        .then((response) => {
          commit('setAstronomy', response)
          resolve(response)
        })
    })
  }
}

const mutations = {
  setWeather(state, weather) {
    state.weather = weather
  },
  setAstronomy(state, astronomy) {
    state.astronomy = astronomy
  },
  setLocation(state, location) {
    state.location.lat = location.lat
    state.location.lng = location.lng
  },
}

const modules = {
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules
}
