/* eslint-disable */
import axios from 'axios'
const userId = localStorage.getItem('user')

export default {
  editUser(payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
            mutation {
              editUser(editUserInput: {name:"${payload.name}", email:"${payload.email}"}) {
                name,
                email
              }
            }
          `
        }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getUserDetails() {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
            query {
              userDetail(userId: "${userId}") {
                name,
                email
              }
            }
          `
        }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAllProfileCards(id) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
              query {
                getAllProfileCards(userId: "${id}") {
                  district {
                    _id,
                    style_id,
                    district_name,
                    district_id,
                  }
                  fishes {
                    fish {
                      _id,
                      fish_id,
                      fish_name
                    }
                    cards {
                      style_id,
                      style_name,
                      district_id,
                      district_name,
                      fish_id,
                      fish_name,
                      lbs,
                      ozs,
                      dr,
                      capture_date,
                      year,
                      month,
											hour,
                      display_description,
                      image_url,
                      _id,
											lat,
											lng,
											air_temperature,
											pressure,
											cloud_cover,
											humidity,
											precipitation,
											wind_speed,
											wind_direction,
											visibility,
											gust,
											current_direction,
											current_speed,
											sea_level,
											swell_direction,
											swell_height,
											swell_period,
											water_temperature,
											wave_direction,
											wave_height,
											sunset,
											sunrise,
											moon_phase_text,
											moon_phase_value,
                    }
                  }
                }
              }
            `
        }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCard(id) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
              query {
                getCard(cardId: "${id}") {
                  style_name,
                  style_id,
                  district_name,
                  district_id,
                  fish_name,
                  fish_id,
                  lbs,
                  ozs,
                  dr,
                  capture_date,
                  display_description,
                  image_url
                }
              }
            `
        }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
