<template>
  <div id="app">
    <error-bar v-if="globalError" />
    <router-view />
    <foot-bar />
  </div>
</template>

<script>
import FootBar from './components/menus/FootBar.vue'
import ErrorBar from './components/utils/ErrorBar.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'App',
  components: {
    FootBar,
    ErrorBar,
  },
  computed: {
    ...mapGetters(['globalError']),
  },
  mounted() {
    this.getUserDetails()
    window.addEventListener('resize', () => {
      if (window.innerHeight <= 400) {
        this.$store.commit('setSmallHeight', true)
      }
      if (window.innerHeight >= 401) {
        this.$store.commit('setSmallHeight', false)
      }
    })
  },
  methods: {
    ...mapActions(['getUserDetails']),
  },
}
</script>

<style lang="scss">
@import "./scss/main.scss";
</style>
