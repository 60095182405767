<template>
  <div>
    <a class="foot-item-btn" @click="back()">
      <i class="foot-item-img fas fa-step-backward"></i>
      <p class="grid-btn-text foot-item-text">Back</p>
    </a>
    <a class="foot-item-btn" @click="menuToggle()">
      <i class="foot-item-img fas fa-bars"></i>
      <p class="grid-btn-text foot-item-text">Menu</p>
    </a>
    <a class="foot-item-btn" @click="profile()">
      <i class="foot-item-img fas fa-user"></i>
      <p class="grid-btn-text foot-item-text">Profile</p>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'create-card-footbar',
  computed: {
    ...mapGetters(['createCardStage']),
  },
  methods: {
    back() {
      this.$emit('menuClose')
      const route = this.$route.name
      if (route === 'MapDistrict') {
        this.$router.push(`/profile/card/create`)
      } else {
        if (this.createCardStage === 1) return
        this.$store.commit('createCardStage', this.createCardStage - 1)
      }
    },
    profile() {
      this.$emit('menuClose')
      this.$emit('profile')
    },
    menuToggle() {
      this.$emit('menuToggle')
    },
  },
}
</script>
