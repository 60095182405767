<template>
  <div>
    <a v-if="pageLocation === 'social'" class="foot-item-btn" @click="createBuddy()">
      <i class="foot-item-img fas fa-plus-square"></i>
      <p class="grid-btn-text foot-item-text">Add buddy</p>
    </a>
    <a v-else class="foot-item-btn" @click="social()">
      <i class="foot-item-img fas fa-plus-square"></i>
      <p class="grid-btn-text foot-item-text">Social</p>
    </a>
    <a class="foot-item-btn" @click="menuToggle()">
      <i class="foot-item-img fas fa-bars"></i>
      <p class="grid-btn-text foot-item-text">Menu</p>
    </a>
    <a class="foot-item-btn" @click="profile()">
      <i class="foot-item-img fas fa-user"></i>
      <p class="grid-btn-text foot-item-text">Profile</p>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'social-footbar',
  methods: {
    createBuddy() {
      this.$router.push('/social/create')
      this.$emit('createBuddy')
    },
    social() {
      this.$router.push('/social')
      this.$emit('social')
    },
    profile() {
      this.$emit('menuClose')
      this.$emit('profile')
    },
    menuToggle() {
      this.$emit('menuToggle')
    },
  },
  computed: {
    ...mapGetters(['pageLocation']),
  },
}
</script>
