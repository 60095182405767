<template>
  <div class="foot-bar" :class="noMenu">
    <div v-if="isAuthenticated" @click.stop>
      <profile-footbar
        v-if="pageLocation === 'profile'"
        class="foot-bar fiveBar"
        @menuToggle="menuToggle()"
        @menuClose="menuClose()"
        @createCard="createCard"
        @editCard="editCard"
      />
      <create-card-footbar
        v-if="pageLocation === 'createCard'"
        class="foot-bar threeBar"
        @menuToggle="menuToggle()"
        @menuClose="menuClose()"
        @profile="profile"
      />
      <social-footbar
        v-if="pageLocation === 'social'"
        class="foot-bar threeBar"
        @menuToggle="menuToggle()"
        @menuClose="menuClose()"
        @profile="profile"
      />
      <social-footbar
        v-if="pageLocation === 'socialCreate'"
        class="foot-bar threeBar"
        @menuToggle="menuToggle()"
        @menuClose="menuClose()"
        @profile="profile"
      />
      <settings-footbar
        v-if="pageLocation === 'settings'"
        class="foot-bar threeBar"
        @menuToggle="menuToggle()"
        @menuClose="menuClose()"
        @profile="profile"
        @createCard="createCard"
      />
    </div>
    <auth-footbar v-else class="foot-bar authBar" @login="logIn" @register="register" />
    <footbar-menu
      :menuActive="menuActive"
      @menuActive="menuToggle"
      @logout="logUserOut()"
    />
  </div>
</template>

<script>
import FootbarMenu from './FootbarMenu'
import ProfileFootbar from './footbar/ProfileFootbar'
import CreateCardFootbar from './footbar/CreateCardFootbar'
import SettingsFootbar from './footbar/SettingsFootbar'
import AuthFootbar from './footbar/AuthFootbar'
import SocialFootbar from './footbar/SocialFootbar'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'foot-bar',
  components: {
    ProfileFootbar,
    CreateCardFootbar,
    FootbarMenu,
    SettingsFootbar,
    SocialFootbar,
    AuthFootbar,
  },
  data() {
    return {
      menuActive: false,
    }
  },
  methods: {
    ...mapActions(['logout']),
    register() {
      this.$store.commit('login', false)
      this.$router.push('/auth')
    },
    logIn() {
      this.$store.commit('login', true)
      this.$router.push('/auth')
    },
    logUserOut() {
      this.logout()
    },
    createCard() {
      this.$router.push('/profile/card/create')
    },
    editCard() {
      this.$router.push(`/profile/card/edit/${this.district}/${this.fish}/${this.page}`)
    },
    profile() {
      const userId = localStorage.getItem('user')
      this.$router.push(`/profile/${userId}`)
    },
    menuToggle(remove) {
      this.menuActive = !this.menuActive
    },
    menuClose() {
      this.menuActive = false
    },
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'pageLocation',
      'allProfileCards',
      'smallHeight',
      'page',
      'district',
      'fish',
      'userDetails',
    ]),
    noMenu: function() {
      return {
        noFootbar: this.smallHeight,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.noFootbar {
  display: none;
}
</style>
