import * as firebase from 'firebase'
import axios from 'axios'
import moment from 'moment'

export default {
  editCard(payload) {
    return new Promise((resolve, reject) => {
      if (!payload.currentImage) {
        const imageName = `${payload.style_id}/${payload.district_id}/${payload.fish_id}/${Date.now()}`
        return firebase.storage().refFromURL(`${payload.image_url}`).delete()
          .then((res) => {
            return firebase.storage().ref(`cards/${imageName}.jpg`).put(payload.image)
              .then(fileData => {
                let imagePath = fileData.metadata.fullPath
                return firebase.storage().ref().child(imagePath).getDownloadURL()
                  .then(url => {
                    let imageUrl = url
                    this.sendEditedCard(payload, imageUrl)
                      .then((res) => {
                        resolve(res)
                      })
                      .catch((err) => {
                        reject(err)
                      })
                  })
              })
          })
      } else {
        let imageUrl = payload.image_url
        this.sendEditedCard(payload, imageUrl)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      }
    })
  },
  sendEditedCard(payload, imageUrl) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
            mutation {
              editCard(editCardInput:
                {
                  lbs: ${payload.lbs},
                  ozs: ${payload.ozs},
                  dr: ${payload.dr},
                  capture_date:"${moment(payload.capture_date).format('DD-MM-YYYY')}",
                  year: "${payload.year}",
                  month: "${payload.month}",
									month: "${payload.month}",
									hour: "${payload.hour}",
                  display_description:"${payload.display_description}",
                  image_url: "${imageUrl}",
                  id: "${payload.id}"
                }) {
                  _id
                }
            }
          `
        }
      })
        .then((response) => {
          resolve(response)
        }
        ).catch(
          err => {
            reject(err)
          }
        )
    })
  },
  storeCard(payload) {
    const data = payload.createCardData ?? {}
    const location = payload.location ?? {}
    const weather = payload.weather.hours[0] ?? {}
    const astronomy = payload.astronomy?.data?.[0] ?? []
    return new Promise((resolve, reject) => {
      const imageName = `${data.style_id}/${data.district_id}/${data.fish_id}/${Date.now()}`
      return firebase.storage().ref(`cards/${imageName}.jpg`).put(data.image)
        .then(fileData => {
          let imagePath = fileData.metadata.fullPath
          return firebase.storage().ref().child(imagePath).getDownloadURL()
            .then(url => {
              let imageUrl = url
              axios({
                url: '/graphql',
                method: 'POST',
                data: {
                  query: `
                    mutation {
                      createCard(cardInput:
                        {
                          style_id: ${data.style_id},
                          style_name: "${data.style_name}",
                          district_id: ${data.district_id},
                          district_name: "${data.district_name}",
                          district_url: "${data.district_url}",
                          fish_id: ${data.fish_id},
                          fish_name: "${data.fish_name}",
                          fish_url: "${data.fish_url}",
                          lbs: ${data.lbs ?? 0},
                          ozs: ${data.ozs ?? 0},
                          dr: ${data.dr ?? 0},
                          capture_date:"${moment(data.capture_date).format('DD-MM-YYYY')}",
                          year: "${data.year}",
                          month: "${data.month}",
                          hour: "${data.hour ?? '12'}",
                          display_description:"${data.display_description}",
                          image_url: "${imageUrl}",
													lat: ${location.lat},
													lng: ${location.lng},
                          air_temperature: ${weather.airTemperature?.sg ?? 0},
                          pressure: ${weather.pressure?.sg ?? 0},
                          cloud_cover: ${weather.cloudCover?.sg ?? 0},
                          humidity: ${weather.humidity?.sg ?? 0},
                          precipitation: ${weather.precipitation?.sg ?? 0},
                          wind_speed: ${weather.windSpeed?.sg ?? 0},
                          wind_direction: ${weather.windDirection?.sg ?? 0},
                          visibility: ${weather.visibility?.sg ?? 0},
                          gust: ${weather.gust?.sg ?? 0},
                          current_direction: ${weather.currentDirection?.sg ?? 0},
                          current_speed: ${weather.currentSpeed?.sg ?? 0},
                          sea_level: ${weather.seaLevel?.sg ?? 0},
                          swell_direction: ${weather.swellDirection?.sg ?? 0},
                          swell_height: ${weather.swellHeight?.sg ?? 0},
                          swell_period: ${weather.swellPeriod?.sg ?? 0},
                          water_temperature: ${weather.waterTemperature?.sg ?? 0},
                          wave_direction: ${weather.waveDirection?.sg ?? 0},
                          wave_height: ${weather.waveHeight?.sg ?? 0},
                          sunset: "${astronomy.sunset ?? `none`}",
                          sunrise: "${astronomy.sunrise ?? `none`}",
                          moon_phase_text: "${astronomy.moonPhase?.current?.text ?? `none`}",
                          moon_phase_value: ${astronomy.moonPhase?.current?.value ?? `none`},
                        }) {
                          _id
                        }
                    }
                  `
                }
              })
                .then((response) => {
                  resolve(response)
                }
                ).catch(
                  err => {
                    return firebase.storage().refFromURL(`${imageUrl}`).delete()
                      .then(() => {
                        reject(err)
                      })
                  }
                )
            })
        })
    })
  }
}
