import axios from 'axios'
import store from '../../store/index'

axios.defaults.baseURL = process.env.VUE_APP_CORE_BASE_URL
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

axios.interceptors.request.use((config) => {
  config.headers = { Authorization: 'Bearer ' + localStorage.getItem('token') }
  return config
}, (error) => {
  return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (!error.response.status) {
    store.commit('globalError', 'Unknown error')
    return Promise.reject(error)
  }
  switch (error.response.status) {
  case 400:
    store.commit('globalError', 'Bad request')
    break
  case 401:
    store.commit('globalError', 'This action is unauthorized')
    break
  case 403:
    store.commit('globalError', 'This action is forbidden')
    break
  case 404:
    store.commit('globalError', 'Content not found')
    break
  case 409:
    store.commit('globalError', 'Conflict error')
    break
  default:
    store.commit('globalError', 'Network error')
  }
  return Promise.reject(error)
})
