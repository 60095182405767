<template>
  <div class="side-menu" :class="toggleSideMenu">
    <section class="fab-item" @click="orderDistrict()">
      <i class="fas fa-list-ol"></i>
      <p class="side-menu-item">Order districts</p>
    </section>
    <section class="fab-item" @click="orderFish()">
      <i class="fas fa-list-ol"></i>
      <p class="side-menu-item">Order fish</p>
    </section>
    <section class="fab-item" @click="share()">
      <i class="fas fa-share-alt-square"></i>
      <p class="side-menu-item">social</p>
    </section>
    <section class="fab-item"  @click="settings()">
      <i class="fas fa-cog"></i>
      <p class="side-menu-item">Settings</p>
    </section>
    <section class="fab-item" @click="logout()">
      <i class="fas fa-sign-out-alt"></i>
      <p class="side-menu-item">Logout</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'footbar-menu',
  props: {
    menuActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    toggleSideMenu() {
      return this.menuActive ? 'openMenu' : 'closeMenu'
    },
  },
  methods: {
    logout() {
      this.$emit('logout')
      this.$emit('menuActive', false)
    },
    orderFish() {
      this.$router.push('/profile/order/fishes')
      this.$emit('menuActive', false)
    },
    orderDistrict() {
      this.$router.push('/profile/order/districts')
      this.$emit('menuActive', false)
    },
    settings() {
      this.$router.push('/profile/settings')
      this.$emit('menuActive', false)
    },
    share() {
      this.$router.push('/social')
      this.$emit('menuActive', false)
    },
  }
}
</script>

<style lang="scss">
.fas {
  font-size: 20px;
  margin: 10px;
}
a {
  text-decoration: none;
  color: black;
}
.openMenu {
  transform: translate(0, -205px);
}
.downItem {
  transform: translate(0, 0);
}
.downMenu {
  transform: translate(0, -190px);
	width: 0;
}
</style>
