<template>
  <div>
    <a @click="login()" class="foot-item-btn">
      <i class="foot-item-img fas fa-sign-in-alt"></i>
      <p class="grid-btn-text foot-item-text">Login</p>
    </a>
    <a @click="register()" class="foot-item-btn">
      <i class="foot-item-img fas fa-user-plus"></i>
      <p class="grid-btn-text foot-item-text">SignUp</p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'create-card-footbar',
  methods: {
    login() {
      this.$emit('login')
      this.$router.push('/auth/login')
    },
    register() {
      this.$emit('register')
      this.$router.push('/auth/register')
    }
  }
}
</script>
