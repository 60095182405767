import api from '../api/social'

const state = {
  allBuddies: null,
  buddies: true,
}

const actions = {
  requestSocialPermission({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      return api.requestSocialPermission(payload)
        .then((response) => {
          resolve(response)
        })
    })
  },
  getSocialBuddies({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      return api.getSocialBuddies()
        .then((response) => {
          const socialBuddies = response.data.data.getSocialBuddies
          const allBuddies = [
            ...socialBuddies.buddies,
            ...socialBuddies.created,
            ...socialBuddies.requested,
          ]
          if (!allBuddies || !allBuddies.length) {
            commit('setNoBuddies', false)
          }
          commit('setAllBuddies', socialBuddies)
          resolve(response)
        })
    })
  },
  grantSocialPermission({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      return api.grantSocialPermission(payload)
        .then((response) => {
          resolve(response)
        })
    })
  }
}

const mutations = {
  setAllBuddies(state, allBuddies) {
    state.allBuddies = allBuddies
  },
  setNoBuddies(state, buddies) {
    state.buddies = buddies
  },
}

const getters = {
  allBuddies: state => {
    return state.allBuddies
  },
  buddies: state => {
    return state.buddies
  },
}

export default {
  state,
  actions,
  mutations,
  getters
}
