// import api from '../api/app'
const state = {
  water: ''
}

const actions = {
}

const mutations = {
  setWater: (state, water) => {
    state.water = water
  }
}

export default {
  state,
  actions,
  mutations
}
