<template>
  <section class="error-bar">
    <p class="words">{{ globalError }}</p>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ErrorBar',
  computed: {
    ...mapGetters([
      'globalError'
    ])
  },
  created() {
    setTimeout(() => {
      this.$store.commit('globalError', null)
    }, 6000)
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
.error-bar {
  padding: 20px;
  background-color: $error-red;
  text-align: center;
  position: absolute;
  width: calc(100vw - 40px);
  top: 0;
}
p {
  color: whitesmoke;
}
</style>
