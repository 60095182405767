import Vue from 'vue'
import Router from 'vue-router'
import lazyLoading from './lazyLoading'
import AuthGuard from './auth-gaurd'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: { name: 'login' }
    },
    {
      path: '/auth/login',
      name: 'login',
      component: lazyLoading('auth/Auth')
    },
    {
      path: '/auth/register',
      name: 'register',
      component: lazyLoading('auth/Auth')
    },
    {
      path: '/auth/verify/email',
      name: 'verifyEmail',
      component: lazyLoading('auth/VerifyEmail')
    },
    {
      path: '/callback/verify/email',
      name: 'CallbackVerify',
      component: lazyLoading('auth/CallbackEmail')
    },
    {
      path: '/settings/password/reset',
      name: 'passwordReset',
      component: lazyLoading('auth/PasswordReset')
    },
    {
      path: '/callback/verify/password',
      name: 'CallbackPassword',
      component: lazyLoading('auth/CallbackPassword')
    },
    {
      path: '/profile/card/create',
      name: 'cardCreate',
      component: lazyLoading('card/CardCreate'),
      beforeEnter: AuthGuard
    },
    {
      path: '/profile/card/edit/:district/:fish/:page',
      name: 'cardEdit',
      component: lazyLoading('card/CardCreate'),
      beforeEnter: AuthGuard
    },
    {
      path: '/profile/settings',
      name: 'settings',
      component: lazyLoading('auth/Settings'),
      beforeEnter: AuthGuard
    },
    {
      path: '/profile/:id',
      name: 'profile',
      component: lazyLoading('profile/Profile'),
      beforeEnter: AuthGuard
    },
    {
      path: '/profile/order/districts',
      name: 'orderDistricts',
      component: lazyLoading('order/OrderDistricts'),
      beforeEnter: AuthGuard
    },
    {
      path: '/profile/order/fishes',
      name: 'orderFishes',
      component: lazyLoading('order/OrderFish'),
      beforeEnter: AuthGuard
    },
    {
      path: '/social',
      name: 'social',
      component: lazyLoading('social/Social'),
      beforeEnter: AuthGuard
    },
    {
      path: '/social/create',
      name: 'socialBuddy',
      component: lazyLoading('social/Create'),
      beforeEnter: AuthGuard
    },
    {
      path: '/map/:type',
      name: 'mapDistrict',
      component: lazyLoading('utils/Map'),
      beforeEnter: AuthGuard
    }
  ]
})
