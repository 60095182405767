// import api from '../api/app'
const state = {
  globalError: null,
  pageLocation: null,
  smallHeight: false,
  weather: false,
  tide: false,
}

const actions = {
}

const mutations = {
  globalError: (state, payload) => {
    state.globalError = payload
  },
  pageLocation: (state, payload) => {
    state.pageLocation = payload
  },
  setSmallHeight: (state, payload) => {
    state.smallHeight = payload
  },
  setWeather: (state, payload) => {
    state.weather = payload
  },
  setTide: (state, payload) => {
    state.tide = payload
  },
}

const getters = {
  pageLocation: state => {
    return state.pageLocation
  },
  smallHeight: state => {
    return state.smallHeight
  },
  globalError: state => {
    return state.globalError
  },
  info: state => {
    return state.info
  },
  weather: state => {
    return state.weather
  },
  tide: state => {
    return state.tide
  }
}

export default {
  getters,
  state,
  actions,
  mutations
}
