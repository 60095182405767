/* eslint-disable */
import axios from 'axios'
import router from '../../router/index'
import store from '../index'

const isAuthenticated = () => {
  let expiresAt = JSON.parse(localStorage.getItem('token_expiration'))
  return new Date().getTime() < expiresAt
}

const fetchUser = () => {
  let userId = localStorage.getItem('user')
  return userId
}

const state = {
  login: true,
  user: fetchUser(),
  authenticated: isAuthenticated(),
  userStates: [],
}

const actions = {
  sendVerificationEmail({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
          query {
            sendVerificationEmail(email:"${payload}") {
              result
            }
          }
        `
        }
      }).then(
          user => {
            resolve(user)
          }
        ).catch(
          err => {
            store.commit('globalError', 'Resend verification failed')
            reject(err)
          }
        )
    })
  },
  updateUserState({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
          mutation {
            updateUserState(updateUserStateInput: {state:"${payload.state}", add:${payload.add}}) {
              userStates
            }
          }
        `
        }
      }).then(
          user => {
            store.commit('userStates', user.data.data.updateUserState.userStates)
            resolve(user)
          }
        ).catch(
          err => {
            store.commit('globalError', 'Incorrect details entered')
            reject(err)
          }
        )
    })
  },
  registerUser({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
          mutation {
            createUser(userInput: {name:"${payload.name}", email:"${payload.email}", password:"${payload.password}"}) {
              _id
              email
              userStates
            }
          }
        `
        }
      })
        .then(
          user => {
            if (user.data.errors) {
              if (user.data.errors[0].message === 'user already exist.') {
                dispatch('signUserIn', payload)
                  .then((res) => {
                    resolve(res)
                  })
              }
            } else {
              const emailVerify = user.data.data.createUser.userStates.includes('email')
              if (emailVerify) {
                resolve(user.data.data.createUser)
              } else {
                dispatch('signUserIn', payload)
                  .then((res) => {
                    resolve(res)
                  })
              }
            }
          }
        )
    })
  },
  signUserIn({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
            query {
              login(email:"${payload.email}", password:"${payload.password}") {
                token
                token_expiration
                user_id
                userStates
              }
            }
          `
        }
      })
        .then(
          user => {
            const emailVerify = user.data.data.login.userStates.includes('email')
            if (emailVerify) {
              router.push('/auth/verify/email')
            } else {
              const newUser = {
                token: user.data.data.login.token,
                id: user.data.data.login.user_id,
                token_expiration: user.data.data.login.token_expiration
              }
              const expiresAt = new Date().getTime() + newUser.token_expiration

              localStorage.setItem('token', newUser.token)
              localStorage.setItem('token_expiration', expiresAt)
              localStorage.setItem('user', newUser.id)
              store.commit('updateAuth', true)
              store.commit('userStates', user.data.data.login.userStates)
              router.push(`/profile/${newUser.id}`)
              resolve(user)
            }
          }
        ).catch(
          err => {
            store.commit('globalError', 'Incorrect details entered')
            reject(err)
          }
        )
    })
  },
  logout({ commit }) {
    localStorage.removeItem('token')
    localStorage.removeItem('token_expiration')
    localStorage.removeItem('user')
    commit('updateAuth', false)
    router.push('/auth')
  },
  verifyEmail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
            query {
              verifyEmail(email:"${payload.email}", token:"${payload.token}") {
                result
              }
            }
          `
        }
      })
        .then(
          result => {
            resolve(result)
          }
        ).catch(
          err => {
            reject(err)
          }
        )
    })
  },
  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
            query {
              resetPassword(email:"${payload}") {
                result
              }
            }
          `
        }
      })
        .then(
          result => {
            if (result.data.errors) {
              store.commit('globalError', result.data.errors[0].message)
            } else {
              resolve(result)
            }
          }
        ).catch(
          err => {
            reject(err)
          }
        )
    })
  },
  verifyResetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
            query {
              verifyResetPassword(email:"${payload.email}", token:"${payload.token}", password:"${payload.password}") {
                result
              }
            }
          `
        }
      })
        .then(
          result => {
            resolve(result)
          }
        ).catch(
          err => {
            reject(err)
          }
        )
    })
  }
}

const mutations = {
  login: (state, payload) => {
    state.login = payload
  },
  updateAuth: (state, payload) => {
    state.authenticated = payload
  },
  userStates: (state, payload) => {
    state.userStates = payload
  }
}

const getters = {
  user: state => {
    return state.user
  },
  login: state => {
    return state.login
  },
  isAuthenticated: state => {
    return state.authenticated
  },
  userStates: state => {
    return state.userStates
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
