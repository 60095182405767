import api from '../../api/profile'

const state = {
  userDetails: null
}

const getters = {
  userDetails: state => {
    return state.userDetails
  }
}

const actions = {
  getUserDetails({ dispatch, commit, rootState }) {
    return new Promise((resolve, reject) => {
      api.getUserDetails()
        .then((response) => {
          commit('setUserDetails', response.data.data.userDetail || null)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  editUser({ dispatch, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      api.editUser(payload)
        .then((res) => {
          commit('setUserDetails', res.data.data.editUser || null)
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  setUserDetails(state, payload) {
    state.userDetails = payload
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
