import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import VeeValidate, { Validator } from 'vee-validate'
import dictionary from './services/veeValidate/dictionary'
import * as firebase from 'firebase'
import VueTouch from 'vue-touch'

import './services/serviceWorker/registerServiceWorker'
import './services/axios/axiosGlobals'

Vue.use(VeeValidate)
Validator.localize('en', dictionary.en)
Vue.use(VueTouch, { name: 'v-touch' })

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  next()
})

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    firebase.initializeApp({
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET
    })
  }
}).$mount('#app')
