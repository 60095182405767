import api from '../../api/order'

const state = {
  allDistricts: null,
  allFishes: null
}

const getters = {
  allDistricts: state => {
    return state.allDistricts
  },
  allFishes: state => {
    return state.allFishes
  }
}

const actions = {
  getAllFishes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.getAllFishes(payload)
        .then((response) => {
          commit('setAllFishes', response.data.data.getFishes || null)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAllDistricts({ commit }) {
    return new Promise((resolve, reject) => {
      api.getAllDistricts()
        .then((response) => {
          commit('setAllDistricts', response.data.data.getDistricts || null)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  orderDistrict({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.orderDistrict(payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  orderFish({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.orderFish(payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  setAllDistricts(state, payload) {
    state.allDistricts = payload
  },
  setAllFishes(state, payload) {
    state.allFishes = payload
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
