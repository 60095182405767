import Vue from 'vue'
import Vuex from 'vuex'

import water from './modules/water'
import auth from './modules/auth'
import app from './modules/app'
import create from './modules/create'
import profileCard from './modules/profile/profileCard'
import profileUser from './modules/profile/profileUser'
import profileOrder from './modules/profile/profileOrder'
import social from './modules/social'
import utils from './modules/utils'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  modules: {
    water,
    auth,
    app,
    create,
    profileCard,
    profileUser,
    profileOrder,
    social,
    utils,
  }
})

Vue.use(store)

export default store
