/* eslint-disable */
import axios from 'axios'
const weatherParams = 'airTemperature,pressure,cloudCover,humidity,precipitation,windSpeed,gust'
const tideParams = 'currentDirection,currentSpeed,seaLevel,swellDirection,swellHeight,swellPeriod,visibility,waterTemperature,waveDirection,waveHeight'
const source = 'sg'

export default {
  setWeather(payload) {
    return new Promise((resolve, reject) => {
			fetch(`https://api.stormglass.io/v2/weather/point?lat=${payload.lat}&lng=${payload.lng}&start=${payload.start}&end=${payload.end}&source=${source}&params=${weatherParams},${tideParams}`, {
			headers: {
				'Authorization': '52abfd30-cb2d-11ed-a138-0242ac130002-52abfe02-cb2d-11ed-a138-0242ac130002'
			}
			}).then((response) => response.json()).then((jsonData) => {
				resolve(jsonData)
			}).catch((err) => {
				resolve({})
			})
    })
  },
	setAstronomy(payload) {
    return new Promise((resolve, reject) => {
				fetch(`https://api.stormglass.io/v2/astronomy/point?lat=${payload.lat}&lng=${payload.lng}&start=${payload.start}&end=${payload.end}&source=${source}`, {
				headers: {
					'Authorization': '52abfd30-cb2d-11ed-a138-0242ac130002-52abfe02-cb2d-11ed-a138-0242ac130002'
				}
			}).then((response) => response.json()).then((jsonData) => {
				resolve(jsonData)
			}).catch((err) => {
				resolve({})
			})
		})
  },
}
