import api from '../../api/profile'
import Style from '../../../components/menus/data/waters'

const state = {
  allProfileCards: null,
  styles: Style,
  page: 0,
  fish: 0,
  district: 0,
}

const getters = {
  page: state => {
    return state.page
  },
  fish: state => {
    return state.fish
  },
  district: state => {
    return state.district
  },
  allStyles: state => {
    return state.styles.waters
  },
  allProfileCards: state => {
    return state.allProfileCards
  }
}

const actions = {
  getAllProfileCards({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.getAllProfileCards(id)
        .then((response) => {
          commit('setAllProfileCards', response.data.data.getAllProfileCards || [])
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCard({ dispatch, commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      api.getCard(id)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

const mutations = {
  setPage(state, page) {
    state.page = page
  },
  setFish(state, fish) {
    state.fish = fish
  },
  setDistrict(state, district) {
    state.district = district
  },
  setAllProfileCards(state, allProfileCards) {
    state.allProfileCards = allProfileCards
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
