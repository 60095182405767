<template>
  <div>
    <a class="foot-item-btn" @click="editCard()">
      <i class="foot-item-img fas fa-edit"></i>
      <p class="grid-btn-text foot-item-text">Edit catch</p>
    </a>
    <a class="foot-item-btn" @click="createCard()">
      <i class="foot-item-img fas fa-plus-square"></i>
      <p class="grid-btn-text foot-item-text">Add catch</p>
    </a>
    <a class="foot-item-btn" @click="menuToggle()">
      <i class="foot-item-img fas fa-bars"></i>
      <p class="grid-btn-text foot-item-text">menu</p>
    </a>
    <a v-if="!weather" class="foot-item-btn" @click="goToWeather()">
      <i class="foot-item-img fas fa-cloud-sun-rain"></i>
      <p class="grid-btn-text foot-item-text">Weather</p>
    </a>
    <a v-else class="foot-item-btn" @click="goToInfoFromWeather()">
      <i class="foot-item-img fas fa-info"></i>
      <p class="grid-btn-text foot-item-text">Info</p>
    </a>
    <a v-if="!tide" class="foot-item-btn" @click="goToTide()">
      <i class="foot-item-img fas fa-water"></i>
      <p class="grid-btn-text foot-item-text">Tide</p>
    </a>
    <a v-else class="foot-item-btn" @click="goToInfoFromTide()">
      <i class="foot-item-img fas fa-info"></i>
      <p class="grid-btn-text foot-item-text">Info</p>
    </a>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'profile-footbar',
  data() {
    return {
      payload: null,
      fishId: null,
      districtId: null,
      styleId: null,
    }
  },
  computed: {
    ...mapGetters([
      'weather',
      'tide',
    ]),
  },
  methods: {
    ...mapActions([
      'getProfileCard'
    ]),
    menuToggle() {
      this.$emit('menuToggle')
    },
    createCard() {
      this.$emit('menuClose')
      this.$emit('createCard')
    },
    editCard() {
      this.$emit('menuClose')
      this.$emit('editCard')
    },
    goToWeather() {
      this.$emit('menuClose')
      this.$store.commit('setWeather', true)
      this.$store.commit('setTide', false)
    },
    goToTide() {
      this.$emit('menuClose')
      this.$store.commit('setTide', true)
      this.$store.commit('setWeather', false)
    },
    goToInfoFromWeather() {
      this.$emit('menuClose')
      this.$store.commit('setWeather', false)
    },
    goToInfoFromTide() {
      this.$emit('menuClose')
      this.$store.commit('setTide', false)
    }
  }
}
</script>
