<template>
  <div>
    <a class="foot-item-btn" @click="createCard()">
      <i class="foot-item-img fas fa-plus-square"></i>
      <p class="grid-btn-text foot-item-text">Add catch</p>
    </a>
    <a class="foot-item-btn" @click="menuToggle()">
      <i class="foot-item-img fas fa-bars"></i>
      <p class="grid-btn-text foot-item-text">Menu</p>
    </a>
    <a class="foot-item-btn" @click="profile()">
      <i class="foot-item-img fas fa-user"></i>
      <p class="grid-btn-text foot-item-text">Profile</p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'create-card-footbar',
  methods: {
    createCard() {
      this.$emit('menuClose')
      this.$emit('createCard')
    },
    profile() {
      this.$emit('menuClose')
      this.$emit('profile')
    },
    menuToggle() {
      this.$emit('menuToggle')
    }
  }
}
</script>
