/* eslint-disable */
import axios from 'axios'

export default {
  orderDistrict(payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
              mutation {
                orderDistrict(districtOrderInput: {districtId:"${payload.id}", orderNo: ${payload.order_no}}) {
                  _id
                }
              }
            `
        }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  orderFish(payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
              mutation {
                orderFish(fishOrderInput: {fishId:"${payload.id}", orderNo: ${payload.order_no}}) {
                  _id
                }
              }
            `
        }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAllDistricts() {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
              query {
                getDistricts {
                  _id,
                  style_id,
                  district_name,
                  district_id
                }
              }
            `
        }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAllFishes(payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
              query {
                getFishes(district_id: ${payload.district_id}, style_id: ${payload.style_id}) {
                  _id,
                  style_id,
                  fish_name
                }
              }
            `
        }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
