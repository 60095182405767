import api from '../api/create'

const state = {
  createCardData: {},
  createCardStage: 1,
}

const getters = {
  createCardData: state => {
    return state.createCardData
  },
  createCardStage: state => {
    return state.createCardStage
  },
}

const actions = {
  editCard({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      return api.editCard(payload)
        .then((response) => {
          resolve(response)
        })
    })
  },
  createCard({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      return api.storeCard(payload)
        .then((response) => {
          resolve(response)
        })
    })
  }
}

const mutations = {
  createCardData: (state, payload) => {
    const newData = {
      ...state.createCardData,
      ...payload,
    }
    state.createCardData = newData
  },
  createCardStage: (state, payload) => {
    state.createCardStage = payload
  }
}

const modules = {
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules
}
