/* eslint-disable */
import axios from 'axios'

export default {
  requestSocialPermission(payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
              mutation {
                requestSocialPermission(permissionInput: {email:"${payload.email}"}) {
                  _id
                }
              }
            `
        }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  grantSocialPermission(payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
              mutation {
                grantSocialPermission(permissionInput: {email:"${payload.email}"}) {
                  _id
                }
              }
            `
        }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
	getSocialBuddies() {
		return new Promise((resolve, reject) => {
			axios({
        url: '/graphql',
        method: 'POST',
        data: {
          query: `
              query {
                getSocialBuddies {
									buddies {
										creatorEmail
										creatorName
										creatorId
										requestedEmail
										requestedName
										requestedId
										granted
									}
									requested {
										creatorEmail
										creatorName
										requestedEmail
										requestedName
									}
									created {
										creatorEmail
										creatorName
										requestedEmail
										requestedName
									}
                }
              }
            `
        }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
		})
	}
}
