export default {
  waters: [
    {
      id: 1,
      color: 'fishcube-green',
      name: 'Stillwater Bait fishing',
    },
    {
      id: 2,
      color: 'fishcube-green',
      name: 'Stillwater fly & lure',
    },
    {
      id: 3,
      color: 'fishcube-green',
      name: 'River Bait fishing',
    },
    {
      id: 4,
      color: 'fishcube-green',
      name: 'River fly & lure',
    },
    {
      id: 5,
      color: 'fishcube-green',
      name: 'Canal Bait fishing',
    },
    {
      id: 6,
      color: 'fishcube-green',
      name: 'Canal fly & lure',
    },
    {
      id: 7,
      color: 'fishcube-green',
      name: 'Sea fishing boat',
    },
    {
      id: 8,
      color: 'fishcube-green',
      name: 'Beach fishing',
    },
  ]
}
